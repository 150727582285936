<template>
  <div class="box">
    <Header />
    <div class="box-img">
      <div class="img-box"></div>
    </div>
    <div class="box-story">
      <div class="title">成长故事</div>

      <div class="story">
        <p class="story-title">{{ user.title }}</p>
        <p class="story-content">{{ user.manifesto }}</p>
        <p class="story-content">
          我在也在网认识了他。在网上聊的时候，我感觉一般，没什么特别的。第一次见面就吃吃饭，聊聊天，没什么特别的，不过，接触一段时间以后，觉得还不错。他是我上辈子的小冤家，今生讨债来了，哈哈......
        </p>
        <p class="story-content">
          说起来不好意思，我们见了3次面，就确定了恋爱关系。哈哈，生活中免不了分歧或争执，如果因为价值观不同而争执，那我们会坐下来好好地沟通，让彼此知道自己的想法。至于家人，他们觉得我俩很合适，很开心我们能够在一起，都很祝福我们。
        </p>
        <p class="story-content">我们已经于2017年6月1日结婚了！</p>
        <p class="story-content">
          我们按习俗、礼节来办了一个传统的婚礼。婚后我们平等分工，共同承担家庭生活的一切。关于孩子的问题，虽然我们都很喜欢，但是我想过几年再考虑这个问题。
        </p>
        <p class="story-content">
          我们在也在网相知相恋，在这里，当着千万会员朋友的面儿，我要向他许下一个爱情的承诺：爱情是以物质为基础的奢侈精神享受
        </p>
        <p class="story-content">
          总结我的恋爱过程，可以概括成一句心得：稳扎稳打，爱情路是漫长的，考验是必须的！
        </p>
        <p class="story-content">
          感谢也在网给了我们这个缘分的平台，感谢小龙女为我们提供这么好的机会。希望更多的单身朋友能够在这里找到自己的另一半。
        </p>
        <p class="story-content">
          我在也在网认识了他。在网上聊的时候，我感觉一般，没什么特别的。第一次见面就吃吃饭，聊聊天，没什么特别的，不过，接触一段时间以后，觉得还不错。他是我上辈子的小冤家，今生讨债来了，哈哈......
        </p>
        <p class="story-content">
          说起来不好意思，我们见了3次面，就确定了恋爱关系。哈哈，生活中免不了分歧或争执，如果因为价值观不同而争执，那我们会坐下来好好地沟通，让彼此知道自己的想法。至于家人，他们觉得我俩很合适，很开心我们能够在一起，都很祝福我们。
        </p>
        <p class="story-content">我们已经于2017年6月1日结婚了！</p>
        <p class="story-content">
          我们按习俗、礼节来办了一个传统的婚礼。婚后我们平等分工，共同承担家庭生活的一切。关于孩子的问题，虽然我们都很喜欢，但是我想过几年再考虑这个问题。
        </p>
        <p class="story-content">
          我们在也在网相知相恋，在这里，当着千万会员朋友的面儿，我要向他许下一个爱情的承诺：爱情是以物质为基础的奢侈精神享受
        </p>
        <p class="story-content">
          总结我的恋爱过程，可以概括成一句心得：稳扎稳打，爱情路是漫长的，考验是必须的！
        </p>
        <p class="story-content">
          感谢也在网给了我们这个缘分的平台，感谢小龙女为我们提供这么好的机会。希望更多的单身朋友能够在这里找到自己的另一半。
        </p>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/directStoreLayout/header.vue";
import Footer from "@/components/directStoreLayout/footer.vue";
import { storyList } from "@/api/member/member.js";
export default {
  data() {
    return {
      listData: [],
      user: "",
    };
  },
  components: {
    Header,
    Footer,
  },
  created() {
    this.storyList();
  },
  methods: {
    async storyList() {
      const { code, data } = await storyList();
      if (code == 0) {
        for (let i = 0; i < 4; i++) {
          this.listData.push(data[i]);
        }
        this.listData.forEach((item) => {
          if (item.id == this.$route.query.Story) {
            this.user = item;
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  .box-img {
    padding-top: 80px;
    .img-box {
      height: 300px;
      background: url("../../../assets/images/banner/flow.png") 100% no-repeat;
      background-size: 100% 100%;
    }
  }
  .box-story {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f7f7f7;
    .title {
      font-size: 34px;
      color: #333;
      margin-top: 80px;
      margin-bottom: 60px;
    }
    .story {
      background-color: #fff;
      min-height: 500px;
      margin-bottom: 80px;
      padding: 50px;
      width: 1200px;
      p {
        width: 1200px;
      }
      .story-title {
        color: #fd686e;
        font-size: 20px;
        text-indent: 1.5em;
        margin-bottom: 20px;
      }
      .story-content {
        text-indent: 2em;
        color: #333;
        font-size: 14px;
        line-height: 25px;
        margin: 10px 0px;
        text-justify: auto;
        text-align: justify;
      }
    }
  }
}
</style>