<template>
  <div class="header">
    <div class="page">
      <!-- <img
        src="../../assets/images/tubiao/yezai_logo.png"
        alt=""
        @click="jump"
      /> 
    src="../../assets/images/tubiao/yezai_logo_A.png"
    -->
      <!-- yezai_logo_B -->
      <img
        src="../../assets/images/tubiao/yezai_logo_B.png"
        alt=""
        @click="jump"
      />
      <ul>
        <!-- <li class="title" @click="jump">直营门店</li>
        <li :class="index == 0 ? 'active' : ''">
          <router-link to="/directStore/home">首页</router-link>
        </li>
        <li :class="index == 1 ? 'active' : ''">
          <router-link to="/directStore/_service">服务介绍</router-link>
        </li>
        <li :class="index == 2 ? 'active' : ''">
          <router-link to="/directStore/_address">门店地址</router-link>
        </li> -->
        <li class="title" @click="jump">直营门店</li>
        <li :class="$store.state.directIndex == 0 ? 'active' : ''">
          <router-link to="/directStore/home">首页</router-link>
        </li>
        <li :class="$store.state.directIndex == 1 ? 'active' : ''">
          <router-link to="/directStore/_service">服务介绍</router-link>
        </li>
        <li :class="$store.state.directIndex == 2 ? 'active' : ''">
          <router-link to="/directStore/_address">门店地址</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: 0,
    };
  },
  created() {
    // this.hun();
  },
  methods: {
    jump() {
      this.$router.push("/n/myyezai");
    },
    // hun() {
    //   if (this.$route.path == "/directStore/home") {
    //     this.idnex = 0;
    //   } else if (this.$route.path == "/directStore/_service") {
    //     this.index = 1;
    //   } else if (this.$route.path == "/directStore/_address") {
    //     this.index = 2;
    //   }
    // },
  },
  // watch: {
  //   $route(to, from) {
  //     // console.log('路由' ,to.fullPath);
  //     console.log("路由", from);
  //     if (to.fullPath) {
  //       // this.index = 0;
  //       this.$store.state.directIndex = 0;
  //     }
  //     if (to.fullPath == "/directStore/home") {
  //       // this.idnex = 0;
  //       this.$store.state.directIndex = 0;
  //     } else if (to.fullPath == "/directStore/_service") {
  //       // this.index = 1;
  //       this.$store.state.directIndex = 1;
  //     } else if (to.fullPath == "/directStore/_address") {
  //       // this.index = 2;
  //       this.$store.state.directIndex = 2;
  //     } else {
  //       // this.index = 0;
  //       this.$store.state.directIndex = 0;
  //     }
  //   },
  // },
};
</script>

<style lang="less" scoped>
.header,
html,
body {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #fd686e;
  position: fixed;
  z-index: 999;
  .page {
    width: 1200px;
    height: 80px;
    background-color: #fd686e;
    display: flex;
    align-items: center;
    ul {
      width: 770px;
      display: flex;
      .title::before {
        content: "";
        display: inline-block;
        width: 1px;
        height: 100%;
        margin-left: 30px;
        margin-right: 24px;
        background: rgba(255, 255, 255, 0.7);
      }
      .title {
        margin-right: 230px;
        cursor: pointer;
        margin-left: 0px;
      }
      li:nth-child(1):hover {
        border: none;
      }
      li {
        color: #ffffff;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        height: 40px;
        // margin: 0px 50px;
        font-size: 16px;
        margin-left: 50px;
        position: relative;
        a {
          color: #ffffff;
        }
      }
      // li:hover {
      //   border-bottom: 2px solid #fff;
      // }
      .active::after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -2px;
        // bottom: -18px;
        width: 30px;
        height: 3px;
        background: white;
        border-radius: 2px;
      }
    }
  }
}
</style>