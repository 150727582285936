<template>
    <div class="footer">
        <Footer/>
    </div>
</template>

<script>
import Footer from '@/components/layout/footer'
export default {
  components: {
    Footer,
  },
};
</script>

<style lang="less" scoped>
.footer {
  display: flex;
  justify-content: center;
  .page {
    width: 1080px;
    line-height: 30px;
    text-align: center;
    .city{
        margin-top: 16px;
    }
    .line {
      height: 2px;
      background-color: #979797FF;
      margin: 18px 0;
    }
    .COPYRIGHT{
        margin-bottom: 16px;
    }
  }
}
</style>